@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeInBackground {
	0% {
		background-color: transparent;
	}
	100% {
		background-color: rgba(0, 0, 0, 0.5);
	}
}


.wrapper {
	z-index: 10;
	position: fixed;
	height: 100vh;
	opacity: 0;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}



.wrapper.show {
	animation: fadeIn 0.5s forwards;
	visibility: visible;
}

.wrapper.show.interaction {
	animation: fadeIn 0.5s forwards, fadeInBackground 0.5s forwards;
}

.wrapper.hide {
	visibility: hidden;
}


.turnstile {
	position: fixed;
	left: 50%;
	bottom: 50px; 
	transform: translateX(-50%);
}

