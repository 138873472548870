:root {
	--backgroundColor: var(--background-dim, #f6f6f6);
	--maxSurveyWrapperWidth: 750px;
}

.pageWrapper {
	background-color: var(--backgroundColor);
	width: 100%;
	height: 100%;
	min-height: 100vh;
}

.surveyWrapper {
	max-width: var(--maxSurveyWrapperWidth);
	margin-inline: auto;
	padding: 1rem;
	padding-top: 3rem;
}

.surveyWrapper .sd-rating__item {
	border-radius: 10px;
}

.surveyWrapper .sd-root-modern {
	background-color: unset;
}

.surveyWrapper .sd-completedpage:before {
	height: 0;
}

.surveyWrapper .sd-body--responsive .sd-page.sd-page__empty-header {
	padding: 0;
}

.sd-body .sd-body__page {
	min-width: 100% !important;
}

.surveyWrapper .sd-page div:first-of-type {
	margin-top: 0;
}

.sd-page__row > * {
	min-width: unset !important;
}

.sd-element {
	border-radius: 1rem !important;
}

@media (max-width: 400px) {
	.sd-element {
		padding: 1rem !important;
	}
}

.sd-element [role="radiogroup"] {
	padding-inline: auto;
	position: relative;
	width: 50rem;
	max-width: 100%;
	padding-bottom: 3rem;
	overflow: hidden;
	display: flex;
	justify-content: space-around;
	align-items: center;
}

.sd-element [role="radiogroup"] > .sd-rating__min-text {
	position: absolute;
	bottom: 0;
	left: 0;
	margin-inline: 0 !important;
}

.sd-element [role="radiogroup"] > .sd-rating__max-text {
	position: absolute;
	bottom: 0;
	right: 0;
	margin-inline: 0 !important;
}

#noInternetIcon {
	display: none;
	height: 1.5rem;
	width: 1.5rem;
}
.disableButtonWrapper {
	position: relative;
	cursor: not-allowed;
}
.disableButtonWrapper #noInternetIcon {
	display: block;
	position: absolute;
	right: 1rem;
}
.disableButton {
	opacity: 0.5;
	pointer-events: none;
}

@media (max-width: 600px) {
	.sd-element [role="radiogroup"] > .sd-rating__min-text {
		font-size: 0.7rem;
	}

	.sd-element [role="radiogroup"] > .sd-rating__max-text {
		font-size: 0.7rem;
	}

	.surveyWrapper {
		padding: 0.25rem;
		padding-top: 0.5rem;
	}
}
